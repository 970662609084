import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
      padding: theme.spacing(2),
    },
  }),
  { name: "FilterBar" },
);

export default useStyles;
