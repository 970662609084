import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(
  (theme) => ({
    resultAlert: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: theme.spacing(2),
    },
    resultAlertAction: {
      marginRight: 0,
    },
    noPAdding: {
      padding: 0,
    },
    noMargin: {
      margin: 0,
    },
    resultAlertTitle: {
      ...theme.typography.h5,
    },
  }),
  { name: "CustomerGrid" },
);

export default useStyles;
