import Grid from "@mui/material/Grid";
import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React from "react";
import { departementsByRegions, secteurs } from "../../../../data";
import { CustomerGridState } from "../types";
import useStyles from "./styles";

export type FilterBarProps = {
  values: CustomerGridState;
  onChange: (inputKey: keyof CustomerGridState, nextValue: string) => void;
};

const FilterBar = ({ values, onChange }: FilterBarProps) => {
  const classes = useStyles();

  const handleChange =
    (selectKey: keyof typeof values) => (event: SelectChangeEvent) => {
      onChange(selectKey, event.target.value);
    };

  return (
    <Paper className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Select
            value={values.sector}
            onChange={handleChange("sector")}
            fullWidth
          >
            <MenuItem value="All">Tous les secteurs d'activité</MenuItem>
            {secteurs.map((s) => (
              <MenuItem key={s.id} value={s.id}>
                {s.nom}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={4}>
          <Select
            value={values.product}
            onChange={handleChange("product")}
            fullWidth
          >
            <MenuItem value="All">Tous les produits</MenuItem>
            <MenuItem value="Agenda">Agenda</MenuItem>
            <MenuItem value="Budget">Budget</MenuItem>
            <MenuItem value="Métronome">Métronome</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={4}>
          <Select
            value={values.departement}
            onChange={handleChange("departement")}
            fullWidth
            placeholder="Tous les produits"
          >
            <MenuItem value="All">Tous les départements</MenuItem>
            {Object.keys(departementsByRegions).map((region) => [
              <ListSubheader key={"region" + region}>{region}</ListSubheader>,
              ...departementsByRegions[region].map((d) => (
                <MenuItem key={"departement" + d.departement} value={d.id}>
                  {d.departement}
                </MenuItem>
              )),
            ])}
          </Select>
        </Grid>
        {/* <Grid item xs={3}>
          <Select
            value={values.staff}
            onChange={handleChange("staff")}
            fullWidth
          >
            <MenuItem value="All">Tous les effectifs</MenuItem>
            <MenuItem value="1-5">De 1 à 5</MenuItem>
            <MenuItem value="6-10">De 6 à 10</MenuItem>
            <MenuItem value="11-20">De 11 à 20</MenuItem>
            <MenuItem value="21-30">De 21 à 30</MenuItem>
            <MenuItem value="31-50">De 31 à 50</MenuItem>
            <MenuItem value="51-70">De 51 à 70</MenuItem>
            <MenuItem value="71-Infinity">Plus de 70</MenuItem>
          </Select>
        </Grid> */}
      </Grid>
    </Paper>
  );
};

export default FilterBar;
