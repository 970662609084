import { useReducer } from "react";
import { CustomerGridState } from "../types";

const InitialState: CustomerGridState = {
  sector: "All",
  departement: "All",
  product: "All",
  staff: "All",
};

export type Action =
  | {
      type: "SET_FILTER";
      payload: {
        inputKey: keyof CustomerGridState;
        nextValue: string;
      };
    }
  | {
      type: "RESET_FILTER";
    };

const useCustomerGridState = () => {
  const [state, dispatch] = useReducer<
    (state: CustomerGridState, action: Action) => CustomerGridState
  >((state, action) => {
    let nextState: CustomerGridState = { ...state };

    switch (action.type) {
      case "SET_FILTER":
        const { inputKey, nextValue } = action.payload;
        nextState[inputKey] = nextValue;
        break;
      case "RESET_FILTER":
        nextState = InitialState;
        break;
    }

    return nextState;
  }, InitialState);

  const dirtyFilters = Object.keys(state).some(
    (key) => state[key as keyof CustomerGridState] !== "All",
  );

  const handleFilterChange = (
    inputKey: keyof CustomerGridState,
    nextValue: string,
  ) => {
    dispatch({
      type: "SET_FILTER",
      payload: {
        inputKey,
        nextValue,
      },
    });
  };

  const handleFilterReset = () =>
    dispatch({
      type: "RESET_FILTER",
    });

  return [state, dirtyFilters, handleFilterChange, handleFilterReset] as const;
};

export default useCustomerGridState;
