import React from "react";

const Spacer = ({ height }: { height: number }) => (
  <div
    style={{
      height,
    }}
  />
);

export default Spacer;
