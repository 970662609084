import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import Spacer from "../../../../components/Spacer";
import { reseaux, secteurs } from "../../../../data";
import { CustomerCardProps } from "./props";
import useStyles from "./styles";
import useTheme from "@mui/styles/useTheme";

const CustomerCard = (props: CustomerCardProps) => {
  const { nom, secteur_id, reseau_id, produits, modules_opt, site_web_url } =
    props;

  const theme = useTheme();

  const reseau = reseaux.find((r) => r.id === (reseau_id || -1));
  const secteur = secteurs.find((s) => s.id === secteur_id);

  const logo = props.logo || reseau?.logo || null;

  const classes = useStyles();

  const handleClick = () => {
    if (site_web_url) {
      window.open(site_web_url, "blank");
    }
  };

  return (
    <Grid item xs={4}>
      <Card>
        <CardActionArea disabled={!site_web_url} onClick={handleClick}>
          <CardMedia
            component="img"
            sx={{ height: 150 }}
            image={`/images/clients/${logo}`}
            alt={nom}
            style={{
              objectFit: "contain",
            }}
            className={classes.logo}
          />
          <Spacer height={16} />
          <CardContent className={classes.content}>
            <Typography
              component="span"
              variant="caption"
              className={classes.sector}
            >
              {secteur?.nom}
            </Typography>
            <Spacer height={8} />
            <Box
              className={classes.chipBox}
              sx={{ display: "flex", alignItems: "center" }}
            >
              {produits.map((produit) => {
                console.log("CustomerCard", produits);

                return (
                  <Chip
                    label={produit}
                    style={{
                      backgroundColor:
                        produit === "Agenda"
                          ? theme.palette.iaBlue.main
                          : produit === "Budget"
                          ? theme.palette.ibPurple.main
                          : produit === "Métronome"
                          ? theme.palette.ibOrange.main
                          : "inherit",
                      color:
                        produit === "Agenda"
                          ? theme.palette.iaBlue.contrastText
                          : produit === "Budget"
                          ? theme.palette.ibPurple.contrastText
                          : produit === "Métronome"
                          ? theme.palette.ibOrange.contrastText
                          : "inherit",
                    }}
                    size="small"
                  />
                );
              })}
            </Box>
            <Spacer height={8} />
            <div
              style={{
                display: "flex",
                minHeight: 80,
              }}
            >
              <Typography
                component="span"
                variant="button"
                className={classes.name}
              >
                {nom}
              </Typography>
            </div>
          </CardContent>
          <Spacer height={16} />
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default CustomerCard;
