import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  logo: {
    paddingTop: 8,
  },
  content: {
    padding: theme.spacing(0, 2),
  },
  name: {
    fontWeight: 600,
    alignSelf: "flex-end",
  },
  sector: {
    backgroundColor: theme.palette.innoGrey.main,
    color: theme.palette.innoGrey.contrastText,
    padding: theme.spacing(0, 0.5),
  },
  chipBox: {
    "& div:not(:last-child)": {
      marginRight: theme.spacing(0.5),
    },
  },
  actions: {
    padding: theme.spacing(0, 2),
    minHeight: 50,
  },
}));

export default useStyles;
