import React from "react";
import Seo from "../components/Seo";
import CustomerGrid from "../views/Customers/CustomerGrid";
import CustomerHero from "../views/Customers/Hero";

const ClientsView = () => {
  return (
    <React.Fragment>
      <Seo title="Nos clients" />
      <CustomerHero />
      <CustomerGrid />
    </React.Fragment>
  );
};

export default ClientsView;
