import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import React, { useState, useEffect, useReducer } from "react";
import Spacer from "../../../components/Spacer";
import { clients } from "../../../data";
import CustomerCard from "./CustomerCard";
import FilterBar from "./FilterBar";
import SectionWrapper from "../../../components/layout/SectionWrapper";
import { sortClientFn } from "../../../util/sort";
import Alert from "@mui/material/Alert";
import useCustomerGridState from "./hooks/useCustomerGridState";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import useStyles from "./styles";
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import clsx from "clsx";

const CustomerGrid = () => {
  const [
    { departement, product, sector, staff },
    dirtyFilters,
    handleFilterChange,
    handleFilterReset,
  ] = useCustomerGridState();

  const [customers, setCustomers] = useState(clients.sort(sortClientFn));

  const classes = useStyles();

  useEffect(() => {
    const nextCustomers = clients.slice().filter((c) => {
      let match = true;

      if (sector !== "All") {
        const secteurId = Number(sector);
        match = match && c.secteur_id === secteurId;
      }

      if (product !== "All") {
        match = match && c.produits.join(",").includes(product);
      }

      if (departement !== "All") {
        const departementId = Number(departement);
        match = match && c.departement_id === departementId;
      }

      if (staff !== "All") {
        let [start, end] = staff.split("-") as any[];
        start = Number(start);
        end = Number(end);

        match = match && c.effectifs >= start && c.effectifs <= end;
      }

      return match;
    });
    setCustomers(nextCustomers);
  }, [sector, product, departement, staff]);

  return (
    <SectionWrapper sectionId="innovance-solutions-customers-section-grid">
      <Container>
        <FilterBar
          values={{
            sector,
            product,
            departement,
            staff,
          }}
          onChange={handleFilterChange}
        />
        <Spacer height={16} />
        {dirtyFilters ? (
          <Alert
            severity="info"
            icon={<MdFilterAlt />}
            classes={{
              root: classes.resultAlert,
              action: clsx(classes.resultAlertAction, classes.noPAdding),
              message: classes.noPAdding,
              icon: classes.noPAdding,
            }}
            action={
              <Button
                variant="contained"
                color="info"
                onClick={handleFilterReset}
                startIcon={<MdFilterAltOff fontSize="small" />}
              >
                Réinitialiser
              </Button>
            }
          >
            <AlertTitle
              classes={{
                root: clsx(classes.noMargin, classes.resultAlertTitle),
              }}
            >
              {customers.length} résultats
            </AlertTitle>
          </Alert>
        ) : null}
        <Spacer height={16} />
        <Grid container spacing={2}>
          {customers.sort().map((customer) => (
            <CustomerCard key={customer.nom} {...customer} />
          ))}
        </Grid>
      </Container>
    </SectionWrapper>
  );
};

export default CustomerGrid;
