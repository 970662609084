import { ClientData } from "./types";

export const sortClientFn = (a: ClientData, b: ClientData) => {
  if (a.nom < b.nom) {
    return -1;
  } else if (a.nom > b.nom) {
    return 1;
  }
  return 0;
};
