import SectionWrapper from "../../../components/layout/SectionWrapper";
import styled from "../../../util/styled";

const PREFIX = "CustomersHero";

export const classes = {
  background: `${PREFIX}-background`,
  typo: `${PREFIX}-typo`,
};

export const StyledSectionWrapper = styled(SectionWrapper)<{
  isMobile: boolean;
}>(({ theme, isMobile }) => ({
  [`& .${classes.typo}`]: {
    color: theme.palette.common.white,
  },

  [`& .${classes.background}`]: {
    backgroundImage: !isMobile
      ? "url('/images/clients/innovance-solutions-references-clients.svg')"
      : "none",
    backgroundPosition: "75%",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundClip: "padding-box",
    backgroundAttachment: "local",
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
}));
