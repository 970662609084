import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import HeroSubtitle from "../../../components/HeroSubtitle";
import HeroTitle from "../../../components/HeroTitle";
import { globalClasses } from "../../../components/layout/MainLayout/styles";
import { StyledSectionWrapper, classes } from "./styles";

const Hero = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledSectionWrapper
      padding="normal"
      isMobile={isMobile}
      sectionId="innovance-solutions-customers-section-hero"
      className={globalClasses.innoBlueGradient}
    >
      <div className={classes.background}>
        <Container>
          <Grid
            item
            sm={12}
            md={6}
            justifyContent={isMobile ? "center" : "flex-start"}
            container
            direction="column"
          >
            <HeroTitle>Nos clients</HeroTitle>
            <HeroSubtitle>
              Découvrez les associations qui, partout sur le territoire français
              et dans tous les secteurs d'activité, choisissent nos solutions
              pour faciliter leur gestion quotidienne et mieux rendre compte de
              leur activité.
            </HeroSubtitle>
          </Grid>
        </Container>
      </div>
    </StyledSectionWrapper>
  );
};

export default Hero;
